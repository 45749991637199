'use client';

import Image from 'next/image';
import Link from 'next/link';
import { useParams } from 'next/navigation';
import NotFoundImage from '@/assets/images/not-found-image.png';

export default function NotFound() {
  const { lang } = useParams();

  return (
    <div className="flex h-full w-full flex-col items-center bg-gray-100 p-5 md:flex-row md:justify-center md:gap-5 md:p-20">
      <div className="flex flex-col gap-4 md:w-1/2 md:gap-10">
        <div className="flex flex-col gap-2 text-gray-900">
          <h1 className="text-3xl font-semibold lg:text-[64px] lg:font-bold lg:leading-[80px]">
            Page not found
          </h1>
          <p className="text-sm lg:text-lg">
            Like a downed power line, this page is currently inactive. Our team
            is working to get it back up and running. In the meantime, please
            return to our Home page.
          </p>
        </div>
        <div className="flex items-center justify-end md:justify-start">
          <Link
            href={`/${lang}`}
            className="w-fit rounded-[35px] bg-primary-900 px-3 py-2"
          >
            <p className="text-sm text-white">Go home</p>
          </Link>
        </div>
      </div>
      <div className="w-full md:w-1/2 lg:max-w-[520px]">
        <Image src={NotFoundImage} alt="not found image" className="w-full" />
      </div>
    </div>
  );
} 